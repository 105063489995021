import React, { Component } from 'react';

import { connect } from 'react-redux';
import { inviteEmployee } from './actions';
import { withFirebase } from './firebase';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eMail: '',
      role: '',
      departments: [],
      _departments: '',
      tags: [],
      _tags: ''
    };
  }

  onChange = (event) => { //check for empty string
    if (event.target.value !== ' ') {
      if (event.target.name === '_tags') {
        if (event.target.value.indexOf(' ') >= 0) {
          this.setState(state => {
            const tags = state.tags.concat(state._tags.trim());
            return {
              tags,
              _tags: '',
            };
          });
        } else {
          this.setState({ [event.target.name]: event.target.value });
        }
      } else if (event.target.name === '_departments') {
        if (event.target.value.indexOf(' ') >= 0) {
          this.setState(state => {
            const departments = state.departments.concat(state._departments.trim());
            return {
              departments,
              _departments: '',
            };
          });
        } else {
          this.setState({ [event.target.name]: event.target.value });
        }
      } else {
        this.setState({ [event.target.name]: event.target.value });
      }
    }
  };

  handleKeyDown = (event) => { //check for empty string
    if (event.key === 'Enter') {
      if (event.target.value !== ' ') {
        if (event.target.name === '_tags') {
          this.setState(state => {
            const tags = state.tags.concat(state._tags);
            return {
              tags,
              _tags: '',
            };
          });
        } else if (event.target.name === '_departments') {
          this.setState(state => {
            const departments = state.departments.concat(state._departments);
            return {
              departments,
              _departments: '',
            };
          });
        }
      }
    }
  }

  render() {
    console.log('this.state', this.state);
    return(
      <div className="modal fade" id="addUserModal" tabIndex="-1" role="dialog" aria-labelledby="addUserModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addUserModalLabel">Mitarbeiter hinzufügen</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-row" style={{marginBottom: 10}}>
                  <div className="col-12">
                    <input
                      name='eMail'
                      onChange={this.onChange}
                      type="email" className="form-control" placeholder="eMail-Adresse"/>
                  </div>
                </div>
                <div className="form-row" style={{marginBottom: 10}}>
                  <div className="col-12">
                    <div className="dropdown">
                      <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {this.state.role === '' ? ('Nutzerrolle') : (this.state.role === 'student' ? 'Kursteilnehmer' : 'Kursersteller')}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button type="button" className="dropdown-item" onClick={() => this.setState({ role: 'student' })}>Kursteilnehmer</button>
                        <button type="button" className="dropdown-item" onClick={() => this.setState({ role: 'author' })}>Kursersteller</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row" style={{marginBottom: 10}}>
                  <div className="col-12">
                    {this.state.departments.length > 0 ? (<h6 style={{marginTop: 8, marginBottom: 4}}>Abteilung(en)</h6>) : null}
                    {(this.state.departments).map(department => (
                      <span key={department} className="badge badge-secondary" style={{marginTop: 4, marginRight: 4, marginBottom: 0}}>{department}</span>
                    ))}
                    <input
                      style={{marginTop: 8}}
                      onKeyDown={this.handleKeyDown}
                      name='_departments'
                      value={this.state._departments}
                      onChange={this.onChange}
                      type="text" className="form-control" placeholder="Abteilung(en)"/>
                    <small class="form-text text-muted">Bestätigen Sie Ihre Eingabe mit Leertaste oder Enter.</small>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    {this.state.tags.length > 0 ? (<h6 style={{marginTop: 8, marginBottom: 4}}>weitere Kriterien</h6>) : null}
                    {(this.state.tags).map(tag => (
                      <span key={tag} className="badge badge-secondary" style={{marginTop: 4, marginRight: 4, marginBottom: 0}}>{tag}</span>
                    ))}
                    <input
                      style={{marginTop: 8}}
                      onKeyDown={this.handleKeyDown}
                      name='_tags'
                      value={this.state._tags}
                      onChange={this.onChange}
                      type="text" className="form-control" placeholder="weitere Kriterien, z.B. Kostenstelle, extern, ..."/>
                    <small class="form-text text-muted">Bestätigen Sie Ihre Eingabe mit Leertaste oder Enter.</small>
                  </div>
                </div>
              </form>
              {
                this.props.inviteEmployeeStatus === 'success' ? (
                  <div class="alert alert-success" style={{marginTop: 16}} role="alert">
                    Ihre Einladung wurde erfolgreich versandt! Der Mitarbeiter wird über die angegebene eMail-Adresse benachrichtigt.
                  </div>
                ) : (
                  this.props.inviteEmployeeStatus === 'error' ? (
                    <div class="alert alert-danger" style={{marginTop: 16}} role="alert">
                      Leider ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal.
                    </div>
                  ) : null
                )
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
              {
                this.state.eMail !== '' && this.state.role !== '' && (this.state.departments.length > 0 || this.state._departments !== '') && (this.state.tags.length > 0 || this.state._tags !== '') ? (
                  <button type="button" className="btn btn-primary" onClick={() => this.props.inviteEmployee(this.props.firebase, this.state.eMail, this.state.role, this.state.departments, this.state._departments, this.state.tags, this.state._tags)}>{this.props.inviteEmployeeLoading ? 'Lädt...' : 'Einladung versenden'}</button>
                ) : (
                  <button type="button" className="btn btn-primary" disabled onClick={() => this.props.inviteEmployee(this.props.firebase, this.state.eMail, this.state.role, this.state.departments, this.state._departments, this.state.tags, this.state._tags)}>Einladung versenden</button>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ employees }) => {
  const { inviteEmployeeLoading, inviteEmployeeStatus } = employees;
  return { inviteEmployeeLoading, inviteEmployeeStatus };
};

export default connect(mapStateToProps, { inviteEmployee })(withFirebase(Modal));
