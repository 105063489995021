export const FETCH_COURSES = 'fetch_courses';
export const FETCH_COURSES_SUCCESS = 'fetch_courses_success';
export const FETCH_EMPLOYEE_COURSES = 'fetch_employee_courses';
export const FETCH_EMPLOYEE_COURSES_SUCCESS = 'fetch_employee_courses_success';
export const SET_EMPLOYEE_ROLE = 'set_employee_role';
export const SET_EMPLOYEE_ROLE_SUCCESS = 'set_employee_role_success';
export const INVITE_EMPLOYEE = 'invite_employee';
export const INVITE_EMPLOYEE_SUCCESS = 'invite_employee_success';

export const FETCH_EMPLOYEES = 'fetch_employees';
export const FETCH_EMPLOYEES_SUCCESS = 'fetch_employees_success';
