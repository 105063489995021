import React from 'react';

import { withAuthorization } from './session';

const Settings = () => (
  <div>
    <h1>Einstellungen</h1>
  </div>
);

const condition = authUser => authUser !== null;
/*
// role-based authorization
const condition = authUser => authUser.role === 'ADMIN';

// permission-based authorization
const condition = authUser => authUser.permissions.canEditAccount;
*/

export default withAuthorization(condition)(Settings);
