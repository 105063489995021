import React, { Component } from 'react';

import { withAuthorization } from './session';

import { connect } from 'react-redux';
import { fetchCourses, fetchEmployees } from './actions';

import EmployeeRow from './EmployeeRow.js';
import Modal from './Modal.js';

class UserManagement extends Component {
  componentDidMount() {
    let { firebase } = this.props;
    this.props.fetchCourses(firebase);
    this.props.fetchEmployees(firebase);
  }

  shortenCourseTitle(title) {
    if (title.length > 16) {
      let shortTitle = title.substring(0, 16);
      return shortTitle + '...';
    } else {
      return title;
    }
  }

  renderTableHead() {
    let { coursesData } = this.props;
    let coursesArray = [];
    if (coursesData !== null) {
      for (var course in coursesData) {
        if (coursesData.hasOwnProperty(course)) {
          coursesArray.push(coursesData[course]); // TODO: order depending on timestamp or name to assure equal order
        }
      }
      return(
        <tr>
          <th scope="col"></th>
          <th scope="col">Name</th>
          <th scope="col">Art</th>
          <th scope="col">Abteilung(en)</th>
          <th scope="col">Kurse:</th>
          {coursesArray.map(course => {
            return(
              <th scope="col" style={{whiteSpace: 'nowrap'}}>
                <div style={{display: 'inline-block', width: 52, transform: 'matrix(1, 0, 0, 1, 24, 0) rotate(-45deg)'/*'rotate(-45deg)'/*, webkitTransform: 'rotate(-45deg)'*/, transformOrigin: 'bottom left'}}>
                  {this.shortenCourseTitle(course.title)}
                </div>
              </th>
            );
          })}
        </tr>
      );
    }
  }

  renderEmployees() {
    let { employeesData, coursesData } = this.props;
    if ((employeesData !== null) && (coursesData !== null)) {
      return employeesData.map((singleEmployee, index) => <EmployeeRow key={index} index={index} employee={singleEmployee} courses={coursesData} />);
    } else {
      return(
        <tr style={{height: 62, backgroundColor: 'rgba(0,0,0,.075)'}}>Lädt...</tr>
      );
    }
  }

  render() {
    return (
      <div>
        <div className="jumbotron jumbotron-fluid" style={{marginTop: 56}}>
          <div className="container">
            <h1 className="display-4">Nutzerverwaltung</h1>
            <p className="lead">Hier können Sie Ihre Mitarbeiter einsehen, hinzufügen und Kurse freischalten.</p>
          </div>
        </div>

        <div className="container" style={{marginBottom: 32}}>
          <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            {/*<p style={{marginBottom: 0, marginRight: 16}}>[3 / 100] Mitarbeitern hinterlegt</p>*/}
            <button className="btn btn-primary" type="button" data-toggle="modal" data-target="#addUserModal">
              <i className="fas fa-user-plus" style={{marginRight: 8}}></i>
              Mitarbeiter hinzufügen
            </button>
          </div>
        </div>

        <div className="container">
          <div className="table-responsive" style={{paddingTop: 96}}>
            <table className="table table-borderless table-hover">
              <thead>
                {this.renderTableHead()}
              </thead>
              <tbody>
                {this.renderEmployees()}
              </tbody>
            </table>
          </div>
        </div>
        <Modal />
      </div>
    );
  }
}

const mapStateToProps = ({ courses, employees }) => {
  const { coursesLoading, coursesData } = courses;
  const { employeesLoading, employeesData } = employees;
  return { coursesLoading, coursesData, employeesLoading, employeesData };
};

const condition = authUser => authUser !== null;

export default connect(mapStateToProps, { fetchCourses, fetchEmployees })(withAuthorization(condition)(UserManagement));
