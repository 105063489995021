import { combineReducers } from 'redux';
import CoursesReducers from './CoursesReducers.js';
import EmployeesReducers from './EmployeesReducers.js';

const rootReducer = combineReducers({
  courses: CoursesReducers,
  employees: EmployeesReducers
});

export default rootReducer;
