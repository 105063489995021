import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";

import { AuthUserContext, withAuthentication } from './session';

import Login from './Login.js';
import Registration from './Registration.js';
import Settings from './Settings.js';
import SignoutButton from './SignoutButton.js';

import UserManagement from './UserManagement.js';

const App = () => (
  <Router>
    <div>
      <Navigation />
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route path="/registrierung">
          <Registration />
        </Route>
        <Route path="/einstellungen">
          <Settings />
        </Route>
        <Route path="/nutzerverwaltung">
          <UserManagement />
        </Route>
      </Switch>
    </div>
  </Router>
);

export default withAuthentication(App);

const NavigationAuth = () => (
  <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
    <div className="container">
      <Link to="/nutzerverwaltung" className="navbar-brand">
        CUURS
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <NavLink to="/nutzerverwaltung" className="nav-link" activeClassName="active">
              Nutzerverwaltung
            </NavLink>
          </li>
          {/*<li className="nav-item">
            <NavLink to="/einstellungen" className="nav-link" activeClassName="active">
              Einstellungen
            </NavLink>
          </li>*/}
          <li className="nav-item" style={{textAlign: 'center'}}>
            <SignoutButton />
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser => authUser ? <NavigationAuth /> : null}
    </AuthUserContext.Consumer>
  </div>
);
