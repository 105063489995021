import {
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEE_COURSES,
  FETCH_EMPLOYEE_COURSES_SUCCESS,
  SET_EMPLOYEE_ROLE,
  SET_EMPLOYEE_ROLE_SUCCESS,
  INVITE_EMPLOYEE,
  INVITE_EMPLOYEE_SUCCESS
} from './types';

/*
*
* FETCH EMPLOYEES
*
**/
export const fetchEmployees = (firebase) => {
  return (dispatch) => {
    dispatch({ type: FETCH_EMPLOYEES });
    let uuid = firebase.currentUser().uid;
    firebase.firestore.collection('users').doc(uuid).get()
      .then(user => {
        if(user.exists) {
          // TODO: check if user is admin
          return user.data().company;
        }
      })
      .then(companyId => firebase.firestore.collection('companies').doc(companyId).get())
      .then(companyObj => {
        let employeePromises = [];
        companyObj.data().employees.forEach(employee => {
          let getEmployees = firebase.firestore.collection('users').doc(employee).get();
          employeePromises.push(getEmployees);
        });
        return Promise.all(employeePromises)
      })
      .then(snapshot => {
        let employees =[];
        snapshot.forEach((employee) => {
          employees.push(employee.data());
        });
        console.log('[fetchEmployees] employees', employees);
        fetchEmployeesSuccess(dispatch, employees);
      })
      .catch(error => {
        console.log('Error fetching employees: ', error.message);
      });
  };
};

const fetchEmployeesSuccess = ( dispatch, employees ) => {
  dispatch({
    type: FETCH_EMPLOYEES_SUCCESS,
    employees: employees
  });
};


/*
*
* FETCH EMPLOYEE COURSES
*
**/
export const fetchEmployeeCourses = (firebase, userId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_EMPLOYEE_COURSES });
    firebase.firestore.collection(`users/${userId}/courses`).onSnapshot(function(querySnapshot) {
      let employeeCourses = {};
      querySnapshot.forEach(function(doc) {
        employeeCourses[doc.id] = doc.data();
      });
      fetchEmployeeCoursesSuccess(dispatch, userId, employeeCourses);
    });
  };
};

const fetchEmployeeCoursesSuccess = ( dispatch, userId, employeeCourses ) => {
  dispatch({
    type: `${FETCH_EMPLOYEE_COURSES_SUCCESS}/${userId}`,
    userId: userId,
    employeeCourses: employeeCourses
  });
};


/*
*
* SET ROLE
*
**/
export const setRole = (firebase, userId, role) => {
  return (dispatch) => {
    dispatch({ type: SET_EMPLOYEE_ROLE });
    //let uuid = firebase.currentUser().uid;
    firebase.firestore.collection('users').doc(userId).update({ role: role })
      .then(function() {
        setRoleSuccess(dispatch, userId, role);
      })
      .catch(function(error) {
        console.error("Error employee role: ", error);
      });
  };
};

const setRoleSuccess = ( dispatch, userId, role ) => {
  dispatch({
    type: `${SET_EMPLOYEE_ROLE_SUCCESS}/${userId}`, //add listener in EmployeeRole.js, like we do with employeeCourses
    userId: userId,
    role: role
  });
};


/*
*
* INVITE EMPLOYEE
*
**/
export const inviteEmployee = (firebase, eMail, role, departments, _departments, tags, _tags) => {
  return (dispatch) => {
    dispatch({ type: INVITE_EMPLOYEE });
    let uuid = firebase.currentUser().uid;
    var invitationRef = firebase.firestore.collection('invitations').doc();
    let invitationId = invitationRef.id;
    firebase.firestore.collection('users').doc(uuid).get()
      .then(user => {
        if(user.exists) {
          return user.data().company;
        }
      })
      .then(companyId => invitationRef.set({
        key: invitationId,
        eMail: eMail,
        accepted: false,
        admin: false,
        company: companyId,
        department: departments.length > 0 ? departments : [_departments],
        role: role,
        tags: tags.length > 0 ? tags : [_tags]
      }))
      .then(function() {
        inviteEmployeeSuccess(dispatch, 'success');
      })
      .catch(function(error) {
        console.error("Error inviting user: ", error);
        inviteEmployeeSuccess(dispatch, 'error');
      });
  };
};

const inviteEmployeeSuccess = ( dispatch, status ) => {
  dispatch({
    type: INVITE_EMPLOYEE_SUCCESS,
    status: status
  });
};
