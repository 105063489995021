import {
  FETCH_COURSES,
  FETCH_COURSES_SUCCESS
} from './types';

/*
*
* FETCH COURSES
*
**/
export const fetchCourses = (firebase) => {
  return (dispatch) => {
    dispatch({ type: FETCH_COURSES });
    let uuid = firebase.currentUser().uid;
    firebase.firestore.collection('users').doc(uuid).get()
      .then(user => {
        if(user.exists) {
          // TODO: check if user is admin
          return user.data().company;
        }
      })
      .then(companyId => firebase.firestore.collection('companies').doc(companyId).collection('courses').get())
      .then(companyCourses => {
        let coursePromises = [];
        companyCourses.forEach(companyCourse => {
          //console.log('[fetchCourses] companyCourses:', companyCourse.id, " => ", companyCourse.data());
          let getCourses = firebase.firestore.collection('courses').doc(companyCourse.id).get();
          coursePromises.push(getCourses);
        });
        return Promise.all(coursePromises)
      })
      .then(snapshot => {
        let courses = [];
        snapshot.forEach((course) => {
          courses.push(course.data());
        });
        fetchCoursesSuccess(dispatch, courses);
        console.log('[fetchCourses] courses:', courses);
      })
      .catch(error => {
        console.log('Error fetching courses: ', error.message);
      });
  };
};

const fetchCoursesSuccess = ( dispatch, courses ) => {
  dispatch({
    type: FETCH_COURSES_SUCCESS,
    courses: courses
  });
};


/*
*
* ADD COURSE TO EMPLOYEE
*
**/
export const addCourseToEmployee = (firebase, employee, courseId) => {
  return (dispatch) => {
    firebase.firestore.collection(`users/${employee}/courses`).doc(courseId).set({ courseId: courseId })
      .then(function() {
        console.log("Document successfully written!");
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  };
};


/*
*
* REMOVE COURSE FROM EMPLOYEE
*
**/
export const removeCourseFromEmployee = (firebase, employee, courseId) => {
  return (dispatch) => {
    firebase.firestore.collection(`users/${employee}/courses`).doc(courseId).delete()
    .then(function() {
      console.log("Course successfully deleted!");
    }).catch(function(error) {
      console.error("Error removing course: ", error);
    });
  };
};
