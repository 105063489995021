import {
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEE_COURSES,
  FETCH_EMPLOYEE_COURSES_SUCCESS,
  SET_EMPLOYEE_ROLE,
  SET_EMPLOYEE_ROLE_SUCCESS,
  INVITE_EMPLOYEE,
  INVITE_EMPLOYEE_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  employeesLoading: false,
  employeesData: null,
  employeeRoleLoading: false,
  employeeRole: null,
  employeeCoursesLoading: false,
  //employeeCoursesData: null,
  inviteEmployeeLoading: false,
  inviteEmployeeStatus: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEES:
      return { ...state, employeesLoading: true };
    case FETCH_EMPLOYEES_SUCCESS:
      return { ...state, employeesLoading: false, employeesData: action.employees };
    case FETCH_EMPLOYEE_COURSES:
      return { ...state, employeeCoursesLoading: true };
    case `${FETCH_EMPLOYEE_COURSES_SUCCESS}/${action.userId}`:
      return { ...state, employeeCoursesLoading: false, [action.userId]: action.employeeCourses/*, employeeCoursesData: null*/ };
    case SET_EMPLOYEE_ROLE:
      return { ...state, employeeRoleLoading: true };



    case `${SET_EMPLOYEE_ROLE_SUCCESS}/${action.userId}`:
      return { ...state, employeeRoleLoading: false, employeeRole: {...state.employeeRole, [action.userId]: action.role} };



    case INVITE_EMPLOYEE:
      return { ...state, inviteEmployeeLoading: true };
    case INVITE_EMPLOYEE_SUCCESS:
      return { ...state, inviteEmployeeLoading: false, inviteEmployeeStatus: action.status };
    default:
      return state;
  }
};
