import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from './firebase';
import { withAuthorization } from './session';

const Login = () => (
  <div>
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  eMail: '',
  password: '',
  error: null
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { eMail, password } = this.state;
    this.props.firebase.doSignInWithEmailAndPassword(eMail, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/nutzerverwaltung');
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { eMail, password, error } = this.state;
    const isInvalid = password === '' || eMail === '';

    return (
      <div className="login-signup-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6 offset-md-3">
              <h1 className="h3 mb-3 font-weight-normal text-center">CUURS - Login</h1>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input
                    className='form-control'
                    name='eMail'
                    value={eMail}
                    onChange={this.onChange}
                    type='email'
                    placeholder='eMail'
                  />
                </div>
                <div className="form-group">
                  <input
                    className='form-control'
                    name='password'
                    value={password}
                    onChange={this.onChange}
                    type='password'
                    placeholder='Passwort'
                  />
                </div>
                {error && <div className="alert alert-warning" role="alert">{error.message}</div>}
                <button type="submit" className="btn btn-lg btn-primary btn-block"  disabled={isInvalid}>
                  <i className="fas fa-sign-in-alt" style={{marginRight: 8}}></i>
                  Login
                </button>
              </form>
              <hr />
              <p className="mb-3 text-muted text-center">Sie haben noch keinen Firmenaccount?</p>
              <Link className="btn btn-lg btn-secondary btn-block" to="/registrierung">
                <i className="fas fa-arrow-alt-circle-right" style={{marginRight: 8}}></i>
                zur Registrierung
              </Link>
              <p className="mt-5 mb-3 text-muted text-center">&copy;2020 <a href="https://cuurs.de/impressum" target="_blank">Impressum</a></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const SignInForm = withRouter(withFirebase(SignInFormBase));

const condition = (authUser) => {
  if(authUser !== null) {
    return false;
  } else {
    return true;
  }
};

export default withAuthorization(condition)(Login);

export { SignInForm };
