import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

var config = {
  apiKey: "AIzaSyD5OwLmDHXB1bXGk8EuCs3MOsPdknM_EcQ",
  authDomain: "cuurs-dev.firebaseapp.com",
  databaseURL: "https://cuurs-dev.firebaseio.com",
  projectId: "cuurs-dev",
  storageBucket: "cuurs-dev.appspot.com",
  messagingSenderId: "386616515368",
  appId: "1:386616515368:web:171d574963122093c28e1d"
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.firestore = firebase.firestore();
  }

  /** AUTH **/
  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () => this.auth.signOut();
  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);
  currentUser = () => this.auth.currentUser;

  /** FIRESTORE **/
  firestore = this.firestore;
}

export default Firebase;
