import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from './firebase';
import { withAuthorization } from './session';

const Registration = () => (
  <div>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  inputCompanyName: '',
  inputAddress: '',
  inputAddressExtra: '',
  inputZip: '',
  inputCity: '',
  department: '',
  firstName: '',
  lastName: '',
  eMail: '',
  password: '',
  accepted: false,
  error: null
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { inputCompanyName, inputAddress, inputAddressExtra, inputZip, inputCity, password, eMail, department, firstName, lastName } = this.state;
    this.props.firebase.doCreateUserWithEmailAndPassword(eMail, password)
      .then((user) => {
        let uuid = user.user.uid;
        let newCompanyRef = this.props.firebase.firestore.collection('companies').doc();
        let newCompanyId = newCompanyRef.id;
        let newCompany = {
          key: newCompanyId,
          name: inputCompanyName,
          address: inputAddress,
          addressExtra: inputAddressExtra,
          zip: inputZip,
          city: inputCity,
          employees: [ uuid ]
        };
        let newUser = {
          role: 'author',
          department: department,
          admin: true,
          company: newCompanyId,
          key: uuid,
          firstName: firstName,
          lastName: lastName,
          eMail: eMail
        };

        let setNewCompany = this.props.firebase.firestore.collection('companies').doc(newCompanyId).set(newCompany);
        let setNewUser = this.props.firebase.firestore.collection('users').doc(uuid).set(newUser);

        return Promise.all([setNewCompany, setNewUser]);
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/nutzerverwaltung');
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = (event) => {
    const target = event.target;
    const value = target.name === 'accepted' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  render() {
    const { inputCompanyName, inputAddress, inputAddressExtra, inputZip, inputCity, firstName, lastName, department, eMail, password, accepted, error } = this.state;
    const isInvalid = inputCompanyName === '' || inputAddress === '' || inputZip === '' || inputCity === '' || password === '' || eMail === ''  || department === ''  || firstName === ''  || lastName === '' || accepted === false;

    return (
      <div className="login-signup-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-8 offset-2">
              <h1 className="h3 mb-3 font-weight-normal text-center">CUURS - Registrierung</h1>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label for="inputCompanyName">Firmenname</label>
                  <input
                    id='inputCompanyName'
                    className='form-control'
                    name='inputCompanyName'
                    value={inputCompanyName}
                    onChange={this.onChange}
                    type='text'
                    placeholder='z.B. Cuurs eLearning GmbH'
                  />
                </div>
                <div className="form-group">
                  <label for="inputAddress">Adresse</label>
                  <input
                    id='inputAddress'
                    className='form-control'
                    name='inputAddress'
                    value={inputAddress}
                    onChange={this.onChange}
                    type='text'
                    placeholder='z.B. Commeniusstraße 103'
                  />
                </div>
                <div className="form-group">
                  <label for="inputAddressExtra">Adresszusatz (optional)</label>
                  <input
                    id='inputAddressExtra'
                    className='form-control'
                    name='inputAddressExtra'
                    value={inputAddressExtra}
                    onChange={this.onChange}
                    type='text'
                    placeholder='1. OG rechts'
                  />
                </div>
                <div className="form-row">
                  <div className="form-group col-md-2">
                    <label for="inputZip">PLZ</label>
                    <input
                      id='inputZip'
                      className='form-control'
                      name='inputZip'
                      value={inputZip}
                      onChange={this.onChange}
                      type='text'
                      placeholder='z.B. 01309'
                    />
                  </div>
                  <div className="form-group col-md-10">
                    <label for="inputCity">Stadt</label>
                    <input
                      id='inputCity'
                      className='form-control'
                      name='inputCity'
                      value={inputCity}
                      onChange={this.onChange}
                      type='text'
                      placeholder='z.B. Dresden'
                    />
                  </div>
                </div>
                <hr />
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label for="firstName">Vorname</label>
                    <input
                      id='firstName'
                      className='form-control'
                      name='firstName'
                      value={firstName}
                      onChange={this.onChange}
                      type='text'
                      placeholder='Vorname'
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label for="lastName">Nachname</label>
                    <input
                      id='lastName'
                      className='form-control'
                      name='lastName'
                      value={lastName}
                      onChange={this.onChange}
                      type='text'
                      placeholder='Nachname'
                    />
                  </div>
                  <div className="form-group col-12">
                    <label for="department">Ihre Abteilung</label>
                    <input
                      id='department'
                      className='form-control'
                      name='department'
                      value={department}
                      onChange={this.onChange}
                      type='text'
                      placeholder='z.B. Personal, Einkauf, ...'
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label for="inputEmail">eMail</label>
                    <input
                      id='inputEmail'
                      className='form-control'
                      name='eMail'
                      value={eMail}
                      onChange={this.onChange}
                      type='email'
                      placeholder='eMail'
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label for="inputPassword">Password</label>
                    <input
                      id='inputPassword'
                      className='form-control'
                      name='password'
                      value={password}
                      onChange={this.onChange}
                      type='password'
                      placeholder='Passwort'
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <input
                      id='accept'
                      className='form-check-input'
                      name='accepted'
                      type='checkbox'
                      checked={this.state.accepted}
                      onChange={this.onChange}
                    />
                    <label className="form-check-label" for="accept">
                      Ich akzeptiere die <a href="https://cuurs.de/agb" target="_blank">allgemeinen Geschäftsbedingungen (AGB)</a> und die <a href="https://cuurs.de/datenschutz" target="_blank">Datenschutzerklärung</a>.
                    </label>
                  </div>
                </div>
                {error && <div className="alert alert-warning" role="alert">{error.message}</div>}
                <button type="submit" className="btn btn-lg btn-primary" disabled={isInvalid}>
                  <i className="fas fa-sign-in-alt" style={{marginRight: 8}}></i>
                  Registrierung abschließen
                </button>
              </form>
              <hr />
              <p className="mb-3 text-muted text-center">Sie haben bereits einen Account?</p>
              <Link className="btn btn-lg btn-secondary btn-block" to="/">
                <i className="fas fa-arrow-alt-circle-left" style={{marginRight: 8}}></i>
                zum Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

const condition = (authUser) => {
  if(authUser !== null) {
    return false;
  } else {
    return true;
  }
};

export default withAuthorization(condition)(Registration);

export { SignUpForm };
