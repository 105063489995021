import React from 'react';

import { withFirebase } from './firebase';

const SignOutButton = ({ firebase }) => (
  <button type="button" className="btn btn-outline-secondary" onClick={firebase.doSignOut}>
    <i className="fas fa-sign-out-alt" style={{marginRight: 8}}></i>
    Ausloggen
  </button>
);

export default withFirebase(SignOutButton);
