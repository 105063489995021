import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchEmployeeCourses, addCourseToEmployee, removeCourseFromEmployee, setRole } from './actions';
import { withFirebase } from './firebase';

class EmployeeRow extends Component {
  componentDidMount() {
    let { firebase } = this.props;
    this.props.fetchEmployeeCourses(firebase, this.props.employee.key);
  }

  componentWillUnmount() {
    //this.unsubscribe();
  }

  render() {
    let { index, employee, courses, employeeCoursesLoading, employeeCoursesData } = this.props;
    console.log('this.props.employeeRole', this.props.employeeRole);
    return(
      <tr>
        <th scope="row">
          <div style={{height: 38, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>{index + 1}</div>
        </th>
        <td style={{whiteSpace: 'nowrap'}}>
          <div style={{height: 38, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>{employee.firstName + ' ' + employee.lastName}</div>
        </td>
        <td>
          <div className="dropdown">
            <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {this.props.employeeRole !== null ? (
                this.props.employeeRole[employee.key] !== undefined ? (this.props.employeeRole[employee.key] === 'student' ? 'Kursteilnehmer' : 'Kursersteller') : (employee.role === 'student' ? 'Kursteilnehmer' : 'Kursersteller')
              ) : (
                employee.role === 'student' ? 'Kursteilnehmer' : 'Kursersteller'
              )}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button type="button" className="dropdown-item" onClick={() => this.props.setRole(this.props.firebase, employee.key, 'student')}>Kursteilnehmer</button>
              <button type="button" className="dropdown-item" onClick={() => this.props.setRole(this.props.firebase, employee.key, 'author')}>Kursersteller</button>
            </div>
          </div>
        </td>
        <td>
          <div style={{height: 38, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>{employee.department}</div>
        </td>
        <td></td>
        {courses.map(course => {
          return(
            <td>
              <div className="form-check form-check-inline">
                {employeeCoursesData === undefined ? (
                  <div>Lädt...</div>
                ) : (
                  employeeCoursesData[course.courseId] !== undefined ? (
                    <button type="button" className="btn btn-primary" onClick={() => this.props.removeCourseFromEmployee(this.props.firebase, employee.key, course.courseId)}>
                      <i className="fas fa-check"></i>
                    </button>
                  ) : (
                    <button type="button" className="btn btn-light"  onClick={() => this.props.addCourseToEmployee(this.props.firebase, employee.key, course.courseId)}>
                      <i className="fas fa-plus"></i>
                    </button>
                  )
                )}
              </div>
            </td>
          );
        })}
      </tr>
    );
  }
}


const mapStateToProps = ({ employees }, ownProps) => {
  const { employeeCoursesLoading, employeeRoleLoading, employeeRole } = employees;
  const employeeCoursesData = employees[ownProps.employee.key];
  return { employeeCoursesLoading, employeeCoursesData, employeeRoleLoading, employeeRole };
};

export default connect(mapStateToProps, { fetchEmployeeCourses, addCourseToEmployee, removeCourseFromEmployee, setRole })(withFirebase(EmployeeRow));
