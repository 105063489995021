import {
  FETCH_COURSES,
  FETCH_COURSES_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  coursesLoading: false,
  coursesData: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_COURSES:
      return { ...state, coursesLoading: true };
    case FETCH_COURSES_SUCCESS:
      return { ...state, coursesLoading: false, coursesData: action.courses };
    default:
      return state;
  }
};
